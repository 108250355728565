// src/components/NavBar.tsx

import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoName from "../assets/logoName.png";
import logo from "../assets/logo.png";
import LanguageSelector from "./LanguageSelector";
import styles from "../styles/NavBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";

const NavBar: React.FC = () => {
  const { t } = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, logout } = useContext(AuthContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [userMoveType, setUserMoveType] = useState<string | null>(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const isLandingPage = location.pathname === "/";

  // Define paths based on userType
  const [userType, setUserType] = useState<"company" | "individual" | null>(
    null
  );

  const registrationPath = "/protected-registration"; // "/registration-organization";
  const organizationPaths = ["/company", "/companies", "/organization"];
  const loginPath = organizationPaths.some((path) =>
    location.pathname.includes(path)
  )
    ? "/login-organization"
    : "/login-individuals";

  // Handle user logout
  const handleLogout = () => {
    logout();
    navigate(loginPath);
  };

  // Function to check if the link is active
  const isActive = (path: string) => location.pathname === path;

  // Inside your NavBar.tsx

  useEffect(() => {
    const fetchUserData = async (
      token: string,
      currentUserType: "company" | "individual"
    ) => {
      try {
        if (currentUserType === "company") {
          // Existing code for company users
          const response = await fetch("https://api.relotu.com/companies", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Fetched company data:", data);
            setAvatarUrl(data.avatar);
            setUserName(data.name);
          } else {
            console.error("Error fetching company data");
          }
        } else if (currentUserType === "individual") {
          // Fetch company data associated with the individual
          const responseCompany = await fetch(
            "https://api.relotu.com/individuals/company",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-relotu-access": token,
              },
            }
          );

          if (responseCompany.ok) {
            const dataCompany = await responseCompany.json();
            console.log("Fetched company data for individual:", dataCompany);
            setAvatarUrl(dataCompany.avatar);
            // Set other company-related data if needed
          } else {
            console.error("Error fetching company data for individual");
          }

          // Fetch individual data to get moveType
          const responseIndividual = await fetch(
            "https://api.relotu.com/individuals",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-relotu-access": token,
              },
            }
          );

          if (responseIndividual.ok) {
            const dataIndividual = await responseIndividual.json();
            console.log("Fetched individual data:", dataIndividual);
            setUserName(dataIndividual.name);

            // Adjust according to your data structure
            // If moveType is inside dataIndividual.moveInfo.moveType
            setUserMoveType(dataIndividual.moveInfo.moveType);
            console.log("User Move Type:", dataIndividual.moveInfo.moveType);
          } else {
            console.error("Error fetching individual data");
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      const storedUserType = localStorage.getItem("loggedAs") as
        | "company"
        | "individual"
        | null;

      if (token && storedUserType) {
        setUserType(storedUserType);
        fetchUserData(token, storedUserType);
      } else {
        console.error("Token or userType not found in localStorage");
        setAvatarUrl(null);
        setUserName(null);
      }
    } else {
      setAvatarUrl(null);
      setUserName(null);
      setUserType(null);
    }
  }, [isAuthenticated]);

  return (
    <nav className={`${styles.nav} ${isLandingPage ? styles.landingNav : ""}`}>
      {isAuthenticated ? (
        <div className={styles.logoAvatarContainer}>
          <Link to="/">
            <img src={logo} alt="Logo" className={styles.logo} />
          </Link>

          <div className={styles.separator}></div>

          {avatarUrl && (
            <div className={styles.avatarContainer}>
              <div className={styles.avatarBox}>
                <img src={avatarUrl} alt="Avatar" className={styles.avatar} />
                {userName && (
                  <span className={styles.userName}>{userName}</span>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Link to="/">
          <img src={logoName} alt="Logo Name" className={styles.logoName} />
        </Link>
      )}
      <FontAwesomeIcon
        icon={isMenuOpen ? faTimes : faBars}
        className={styles.hamburger}
        onClick={toggleMenu}
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
      />
      <div className={isMenuOpen ? styles.navMenuActive : styles.navMenu}>
        <ul className={styles.navList}>
          {!isLandingPage && (
            <>
              {isAuthenticated ? (
                userType === "company" ? (
                  <>
                    <li
                      className={
                        isActive("/dashboard-companies") ? styles.active : ""
                      }
                    >
                      <Link to="/dashboard-companies">
                        {t("navbar.dashboard")}
                      </Link>
                    </li>
                    <li
                      className={
                        isActive("/people-insights") ? styles.active : ""
                      }
                    >
                      <Link to="/people-insights">
                        {t("navbar.people-insights")}
                      </Link>
                    </li>
                    <li
                      className={
                        isActive("/company-tasks") ? styles.active : ""
                      }
                    >
                      <Link to="/company-tasks">
                        {t("navbar.company-tasks")}
                      </Link>
                    </li>
                    <li
                      className={isActive("/videolibrary") ? styles.active : ""}
                    >
                      <Link to="/videolibrary">{t("navbar.videolibrary")}</Link>
                    </li>
                  </>
                ) : userType === "individual" ? (
                  <>
                    <li
                      className={
                        isActive("/dashboard-individuals") ? styles.active : ""
                      }
                    >
                      <Link to="/dashboard-individuals">
                        {t("navbar.dashboard")}
                      </Link>
                    </li>
                    <li className={isActive("/chat") ? styles.active : ""}>
                      <Link to="/chat">{t("navbar.chat")}</Link>
                    </li>
                    <li
                      className={`${
                        isActive("/my-tasks") ? styles.active : ""
                      }`}
                    >
                      {userMoveType === "interested" ? (
                        // Enlace deshabilitado con icono de candado y tooltip
                        <span className={styles.disabledLinkContainer}>
                          <span className={styles.disabledLink}>
                            {t("navbar.my-tasks")}
                            <FontAwesomeIcon
                              icon={faLock}
                              className={styles.lockIcon}
                            />
                          </span>
                          <span className={styles.tooltipText}>
                            {t("navbar.tooltip-disabled-link")}
                          </span>
                        </span>
                      ) : (
                        <Link to="/my-tasks">{t("navbar.my-tasks")}</Link>
                      )}
                    </li>

                    <li
                      className={isActive("/videolibrary") ? styles.active : ""}
                    >
                      <Link to="/videolibrary">{t("navbar.videolibrary")}</Link>
                    </li>
                  </>
                ) : null
              ) : (
                <>
                  <li
                    className={
                      isActive("/individuals-landing-page") ? styles.active : ""
                    }
                  >
                    <Link to="/individuals-landing-page">
                      {t("navbar.for-individuals")}
                    </Link>
                  </li>
                  <li
                    className={
                      isActive("/companies-landing-page") ? styles.active : ""
                    }
                  >
                    <Link to="/companies-landing-page">
                      {t("navbar.for-companies")}
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
          <li className={isActive("/contact-us") ? styles.active : ""}>
            <Link to="/contact-us">{t("navbar.contact-us-button")}</Link>
          </li>

          {!isLandingPage && (
            <>
              {isAuthenticated ? (
                <li>
                  <button
                    type="button"
                    className={styles.registrationButton}
                    onClick={handleLogout}
                  >
                    {t("navbar.logout-button")}
                  </button>
                </li>
              ) : (
                <>
                  <li>
                    <Link to={registrationPath}>
                      <button className={styles.registrationButton}>
                        {t("navbar.register-button")}
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to={loginPath}>
                      <button className={styles.navBarButton}>
                        {t("navbar.login-button")}
                      </button>
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
        <LanguageSelector />
      </div>
    </nav>
  );
};

export default NavBar;
