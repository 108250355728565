// src/components/Chat.tsx

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  FormEvent,
} from "react";
import logo from "../../assets/logo.png";
import styles from "../../styles/Chat styles/chat.module.css";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RequiredActionFunctionToolCall } from "openai/resources/beta/threads/runs/runs";
import { useTranslation } from "react-i18next";

type MessageProps = {
  role: "user" | "assistant";
  text: string;
  isLoading?: boolean; // Añadida propiedad opcional para mensajes de carga
};

const UserMessage: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className={`${styles.message} ${styles.userMessage}`}>{text}</div>
  );
};

const AssistantMessage: React.FC<{ text: string; isLoading?: boolean }> = ({
  text,
  isLoading,
}) => {
  return (
    <div
      className={`${styles.message} ${styles.assistantMessage} ${
        isLoading ? styles.assistantLoading : ""
      }`}
    >
      {isLoading ? (
        <span className={styles.assistantLoadingText}>
          Finding the best answer to assist you
        </span>
      ) : (
        <Markdown>{text}</Markdown>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ role, text, isLoading }) => {
  return role === "user" ? (
    <UserMessage text={text} />
  ) : (
    <AssistantMessage text={text} isLoading={isLoading} />
  );
};

type ChatProps = {
  functionCallHandler?: (
    toolCall: RequiredActionFunctionToolCall
  ) => Promise<string>;
};

const predefinedQuestions: string[] = [
  "question1",
  "question2",
  "question3",
  "question4",
];

const Chat: React.FC<ChatProps> = ({
  functionCallHandler = () => Promise.resolve(""),
}) => {
  const { t, i18n } = useTranslation("global"); // Obtiene el hook de traducción
  const [userInput, setUserInput] = useState<string>("");
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string | null>(() => {
    // Inicializar threadId desde localStorage si existe
    return localStorage.getItem("threadId");
  });
  const [error, setError] = useState<string | null>(null);
  const [isCreatingThread, setIsCreatingThread] = useState<boolean>(false);

  const { isAuthenticated, userType, token } = useContext(AuthContext); // Accede al contexto de autenticación

  // Referencia para evitar múltiples creaciones de thread en StrictMode
  const hasInitializedThread = useRef(false);

  // Referencia para auto-scroll
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Crear un thread solo si no existe y evitar duplicados
  useEffect(() => {
    const initializeThread = async () => {
      if (hasInitializedThread.current || threadId) return; // Evitar múltiples llamadas

      try {
        setIsCreatingThread(true);
        const res = await fetch(`https://api.relotu.com/chatbot/threads`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
          body: JSON.stringify({}), // Enviar datos si son necesarios
        });

        if (!res.ok) {
          // Intenta obtener el mensaje de error del servidor
          const errorText = await res.text();
          throw new Error(`Error al crear el thread: ${errorText}`);
        }

        const data = await res.json();
        console.log("Thread creado con ID:", data.id); // Asegúrate de que el backend devuelve 'id'

        if (data.id) {
          setThreadId(data.id);
          localStorage.setItem("threadId", data.id);
          hasInitializedThread.current = true; // Marcar como inicializado
        } else {
          throw new Error("El backend no devolvió 'id'.");
        }
      } catch (error: any) {
        console.error("Error al crear el thread:", error);
        setError("No se pudo crear el hilo de conversación.");
      } finally {
        setIsCreatingThread(false);
      }
    };

    if (isAuthenticated && !threadId) {
      initializeThread();
    }
  }, [isAuthenticated, threadId, token]);

  const sendMessage = async (text: string) => {
    if (!threadId) {
      setError("No se ha creado un hilo de conversación.");
      return;
    }

    try {
      const res = await fetch(
        `https://api.relotu.com/chatbot/threads/${threadId}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
          body: JSON.stringify({
            message: text,
          }),
        }
      );

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(`Error sending the message: ${errorText}`);
      }

      // Obtener la respuesta como texto
      const responseText = await res.text();

      // Reemplazar el último mensaje (placeholder) con la respuesta real
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (
          lastMessage.role === "assistant" &&
          lastMessage.text === "Finding the best answer to assist you..."
        ) {
          updatedMessages[updatedMessages.length - 1] = {
            role: "assistant",
            text: responseText,
          };
        } else {
          updatedMessages.push({ role: "assistant", text: responseText });
        }
        return updatedMessages;
      });

      setInputDisabled(false); // Rehabilitar el input después de recibir respuesta
    } catch (error: any) {
      console.error("Error sending the message:", error);
      setError(error.message || "Error sending the message.");
      setInputDisabled(false); // Rehabilitar el input en caso de error
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    // Agregar el mensaje del usuario y el placeholder del asistente con isLoading
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: userInput },
      {
        role: "assistant",
        text: "Finding the best answer to assist you...",
        isLoading: true,
      },
    ]);

    setInputDisabled(true); // Deshabilitar input mientras se envía el mensaje
    sendMessage(userInput);
    setUserInput("");
    scrollToBottom();
  };

  const handlePredefinedQuestionClick = (questionKey: string) => {
    const translatedQuestion = t(`chatbot.predefinedQuestions.${questionKey}`);
  
    // Agregar el mensaje predefinido del usuario (ya traducido)
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: translatedQuestion },
      {
        role: "assistant",
        text: "Finding the best answer to assist you...",
        isLoading: true,
      },
    ]);
  
    setInputDisabled(true); // Deshabilitar input mientras se envía el mensaje
    sendMessage(translatedQuestion);
    scrollToBottom();
  };
  

  return (
    <div className={styles.chatContainer}>
      {/* Mensaje de bienvenida */}
      {messages.length === 0 && (
        <div className={styles.introContainer}>
          <h3 className={styles.introTitle}>{t("chatbot.welcomeTitle")}</h3>
          <p className={styles.introText}>{t("chatbot.welcomeText")}</p>
        </div>
      )}

      {/* Contenedor de mensajes */}
      <div className={styles.messagesContainer}>
        <div className={styles.messages}>
          {messages.map((msg, index) => (
            <Message
              key={index}
              role={msg.role}
              text={msg.text}
              isLoading={msg.isLoading}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Preguntas predefinidas */}
      {messages.length === 0 && (
        <div className={styles.predefinedQuestions}>
          {predefinedQuestions.map((questionKey, index) => (
            <div
              className={styles.questionBox}
              key={index}
              onClick={() => handlePredefinedQuestionClick(questionKey)}
            >
              {t(`chatbot.predefinedQuestions.${questionKey}`)}
            </div>
          ))}
        </div>
      )}

      {/* Formulario de envío de mensajes */}
      <form
        onSubmit={handleSubmit}
        className={`${styles.inputForm} ${styles.clearfix}`}
      >
        <input
          type="text"
          className={styles.input}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder={t("chatbot.inputPlaceholder")}
          disabled={inputDisabled}
          required
        />
        <button
          type="submit"
          className={styles.button}
          disabled={inputDisabled}
        >
          <FontAwesomeIcon icon={faEnvelope} className={styles.buttonIcon} />
          {t("chatbot.sendButton")}
        </button>
      </form>

      {/* Mostrar error si existe */}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Chat;
