// src/components/Footer.tsx

import React, { useContext } from "react";
import styles from "../styles/Footer.module.css";
import logo from "../assets/logoName.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRocket,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import AuthContext
import { AuthContext } from "../context/AuthContext";

const Footer: React.FC = () => {
  const { t } = useTranslation("global");
  const { isAuthenticated } = useContext(AuthContext);

  // Do not render footer if user is authenticated
  if (isAuthenticated) {
    return null;
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footerLogoSection}>
        <img src={logo} alt="Logo" className={styles.logoFooter} />
        <div className={styles.buttonContainer}>
          <Link to="/individuals-landing-page" style={{ textDecoration: "none" }}>
            <button className={styles.footerButtonLeft}>
              <FontAwesomeIcon icon={faRocket} className={styles.icon} />
              {t("footer.lets-start-button")}
            </button>
          </Link>

          <Link to="/contact-us" style={{ textDecoration: "none" }}>
            <button className={styles.footerButtonRight}>
              <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
              {t("footer.contact-us-button")}
            </button>
          </Link>
        </div>
      </div>

      <div className={styles.sectionList}>
        <div className={styles.footerList}>
          <ul>
            <h3>{t("footer.menu-title")}</h3>
            <li>
              <Link to="/">{t("footer.menu-home")}</Link>
            </li>
            <li>
              <Link to="/contact-us">{t("footer.menu-contact")}</Link>
            </li>
            <li>
              <Link to="/impressum">{t("footer.menu-impressum")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerList}>
          <ul>
            <h3>{t("footer.services-title")}</h3>
            <li>
              <Link to="/companies-landing-page">
                {t("footer.services-company")}
              </Link>
            </li>
            <li>
              <Link to="/individuals-landing-page">{t("footer.services-mover")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerList}>
          <ul>
            <h3>{t("footer.resources-title")}</h3>
            <li>
              <Link to="/privacy-policy">
                {t("footer.resources-privacy-policy")}
              </Link>
            </li>
            <li>
              <Link to="/cookies-settings">{t("footer.resources-cookies")}</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.section}>
        <p>
          &copy; {new Date().getFullYear()} {t("footer.rights-reserved")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
