// CookieHandler.tsx
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import styles from "../../styles/Legal styles/CookieHandler.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface CookieHandlerProps {
  privacyPolicyLink: string;
  cookieSettingsLink: string;
}

const CookieHandler: React.FC<CookieHandlerProps> = ({
  privacyPolicyLink,
  cookieSettingsLink,
}) => {
  const [t, i18n] = useTranslation("global");

  const [cookies, setCookie, removeCookie] = useCookies(["cookieAccepted"]);
  const [accepted, setAccepted] = useState<boolean | undefined>(
    cookies.cookieAccepted
  );

  useEffect(() => {
    setAccepted(cookies.cookieAccepted);
  }, [cookies]);

  const acceptCookies = () => {
    setCookie("cookieAccepted", true, { path: "/" });
    setAccepted(true);
  };

  // If cookies were accepted, return null
  if (accepted) {
    return null;
  }

  return (
    <div className={styles.cookieHandler}>
      <div className={styles.iconHeaderWrapper}>
        <FontAwesomeIcon icon={faCookieBite} className={styles.cookieIcon} />
        <h1 className={styles.cookieHeader}>{t("cookies.handler-title")}</h1>
      </div>
      <p>
        <span className={styles.cookieText}>
          {t("cookies.handler-text")}{" "}
          <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
            {t("cookies.handler-privacy-policy")}{" "}
          </a>
        </span>
      </p>
      <button className={styles.cookieHandlerButton} onClick={acceptCookies}>
        {t("cookies.handler-button")}{" "}
      </button>
      <a
        href={cookieSettingsLink}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.cookieHandlerLink}
      >
        {t("cookies.handler-cookie-settings")}{" "}
      </a>
    </div>
  );
};

export default CookieHandler;
