// PrivacyPolicy.tsx
import React from "react";
import styles from "../../styles/Legal styles/PrivacyPolicy.module.css"
import { useTranslation } from "react-i18next";

const PrivacyPolicy: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>{t("privacy-policy.title")}</h1>

      {/* Section 1 */}
      <h2 className={styles.header}>{t("privacy-policy.title-section-A")}</h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-A-paragraph1")} <br />
        <br />
        {t("privacy-policy.section-A-paragraph2")} <br />
        <br />
        {t("privacy-policy.section-A-paragraph3")}{" "}
        <a
          href="https://www.relotu.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.relotu.com{""}
        </a>
        {t("privacy-policy.section-A-paragraph4")} <br />
        <br />
        {t("privacy-policy.section-A-paragraph5")} <br />
        <br />
        {t("privacy-policy.section-A-paragraph6")} <br />
        <br />
        {t("privacy-policy.section-A-paragraph7")}
      </p>

      {/* Section 2 */}
      <h2 className={styles.header}>{t("privacy-policy.title-section-B")} </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-B-paragraph1")}
        <br />
        <br />
        <b> Finli GmbH Talstrasse</b> 88 CH-8808 Pfäffikon SZ
        <br />
        <br />
        {t("privacy-policy.section-B-paragraph2")}
        <br />
        <br /> <a href="mailto:info@relotu.com">info@relotu.com</a> <br />
        <br />
        {t("privacy-policy.section-B-paragraph3")}
      </p>

      {/* Section 3 */}
      <h2 className={styles.header}>{t("privacy-policy.title-section-C")} </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-C-paragraph1")}{" "}
        <a
          href="https://www.relotu.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.relotu.com{""}
        </a>{" "}
        {t("privacy-policy.section-C-paragraph1-2")}
        <b>{t("privacy-policy.section-C-paragraph1-bold")}</b>
        {t("privacy-policy.section-C-paragraph1-3")}
        <br />
        <br /> <b> {t("privacy-policy.section-C-paragraph2-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph2")} <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph3-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph3")}
        <br />
        <br />
        {t("privacy-policy.section-C-paragraph4")} <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph5-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph5")}
        <br />
        <br />
        {t("privacy-policy.section-C-paragraph6")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph7-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph7")} <br />
        <br />
        {t("privacy-policy.section-C-paragraph8")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph9-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph9")}
        <br />
        <br />
        {t("privacy-policy.section-C-paragraph10")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph11-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph11")}
        <br />
        <br />
        {t("privacy-policy.section-C-paragraph12")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph13-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph13")}
        <br />
        <br />
        {t("privacy-policy.section-C-paragraph14")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph15-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph15")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph16-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph16")}
        {t("privacy-policy.section-C-paragraph17")}
        <br />
        <br />
        <b>{t("privacy-policy.section-C-paragraph17-bold")}</b>{" "}
        {t("privacy-policy.section-C-paragraph17")}
      </p>

      {/* Section 4 */}
      <h2 className={styles.header}>{t("privacy-policy.title-section-D")}</h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-D-paragraph1")}
        <br />
        <br />
        {t("privacy-policy.section-D-paragraph2")}
        <a
          href="http://www.relotu.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.relotu.com
        </a>
        {t("privacy-policy.section-D-paragraph2-1")}
        <br />
        <br />
        {t("privacy-policy.section-D-paragraph3")}
        <br />
        <br />
        <ul>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list1-item1")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list1-item2")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list1-item3")}
          </li>
        </ul>
        <br />
        {t("privacy-policy.section-D-paragraph4")}
        {t("privacy-policy.section-D-paragraph5")}
        <br />
        <br />
        {t("privacy-policy.section-D-paragraph5")}
        <br />
        <br />
        {t("privacy-policy.section-D-paragraph6")}
        <br />
        <ul>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item1")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item2")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item3")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item4")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item5")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item6")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list2-item7")}
          </li>
        </ul>
        <br />
        {t("privacy-policy.section-D-paragraph7")}
        <br />
        <ul>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list3-item1")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list3-item2")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list3-item3")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list3-item4")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list3-item5")}
          </li>
        </ul>
        <br />
        {t("privacy-policy.section-D-paragraph8")}
        <br />
        <ul>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item1")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item2")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item3")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item4")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item5")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item6")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item7")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-D-list4-item8")}
          </li>
        </ul>
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-E")} </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-E-paragraph1")} <br />
        <br />
        <ul>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item1")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item2")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item3")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item4")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item5")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item6")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item7")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-E-list1-item8")}
          </li>
        </ul>
        <br />
        {t("privacy-policy.section-E-paragraph2")}
      </p>
      {/*Section 6*/}
      <h2 className={styles.header}>{t("privacy-policy.title-section-F")}</h2>
      <p className={styles.paragraph}>
        <span>{t("privacy-policy.section-F-paragraph1-bold")}</span>{" "}
        {t("privacy-policy.section-F-paragraph1")}
        <br />
        <br />
        <span>{t("privacy-policy.section-F-paragraph2-bold")}</span>{" "}
        {t("privacy-policy.section-F-paragraph2")}
      </p>
      <h2 className={styles.header}>
        {/*Section 6*/}
        {t("privacy-policy.title-section-G")}
      </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-G-paragraph1")}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-H")}</h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-H-paragraph1")}
        <br />
        <br />
        {t("privacy-policy.section-H-paragraph2")}{" "}
        <a
          href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?
        </a>{" "}
        {t("privacy-policy.section-H-paragraph3")}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-I")} </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-I-paragraph1")}
        <br />
        <br />
        {t("privacy-policy.section-I-paragraph2")}

        <br />
        <br />
        {t("privacy-policy.section-I-paragraph3")}

        <br />
        <br />
        {t("privacy-policy.section-I-paragraph4")}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-J")} </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-J-paragraph1")}
        <br />
        <br />
        {t("privacy-policy.section-J-paragraph2")}
        <br />
        <br />
        <ul>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-J-list1-item1")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-J-list1-item2")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-J-list1-item3")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-J-list1-item4")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-J-list1-item5")}
          </li>
          <li className={styles.bulletList}>
            {t("privacy-policy.section-J-list1-item6")}
          </li>
        </ul>
        <br />
        {t("privacy-policy.section-J-paragraph3")}
        <br />
        <br />
        {t("privacy-policy.section-J-paragraph4")}
        <br />
        <br />
        {t("privacy-policy.section-J-paragraph5")}
        <br />
        <br />
        {t("privacy-policy.section-J-paragraph6")}
        <a
          href="https://edpb.europa.eu/about-edpb/board/members_en."
          target="_blank"
          rel="noopener noreferrer"
        >
          https://edpb.europa.eu/about-edpb/board/members_en.
        </a>{" "}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-K")}</h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-K-paragraph1")}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-L")} </h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-L-paragraph1")}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-M")}</h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-M-paragraph1")}
        <br />
        <br />
        Finli GmbH, Talstrasse 88, 8808 Pfäffikon SZ, Switzerland
        <br />
        <br />
        {t("privacy-policy.section-M-paragraph2")}
        <a href="mailto:info@relotu.com">info@relotu.com</a>.
        <br />
        <br />
        {t("privacy-policy.section-M-paragraph3")}{" "}
        <a href="mailto:info@relotu.com">info@relotu.com</a>
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-N")}</h2>
      <p className={styles.paragraph}>
        {t("privacy-policy.section-N-paragraph1")}
      </p>
      <h2 className={styles.header}>{t("privacy-policy.title-section-O")}</h2>

      <p className={styles.paragraph}>
        {t("privacy-policy.section-O-paragraph1")}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
