import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import styles from "../../styles/VideoLibrary styles/VideoLibrary.module.css";

// SVG Icons para las flechas
const LeftArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const RightArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// Define la interfaz Video con un campo adicional 'topic'
interface Video {
  id: number;
  title: string;
  url: string; // URL de embed (ej., YouTube)
  topic: string; // Categoría del tema
}


// Categoriza los videos en temas
const videos: Video[] = [
  {
    id: 1,
    title: "Krankenversicherung Schweiz Generelle Infos",
    url: "https://www.youtube.com/embed/0RlsS0RvQrY?si=8BIF8j_MAO6V89UB",
    topic: "General Relocation",
  },
  {
    id: 2,
    title: "Relotu: Diplomanerkennung als Arzt oder Ärztin",
    url: "https://www.youtube.com/embed/rWfHK06eSsM?si=gqQj44dZxSVFZoYP",
    topic: "Diploma Recognition",
  },
  {
    id: 3,
    title: "Tipps für die Wohnungssuche in der Schweiz",
    url: "https://www.youtube.com/embed/nGn_dpfxkik?si=_-c4R7kgLVM_n63v",
    topic: "Apartment Search",
  },
  {
    id: 4,
    title: "Krankenversicherung Schweiz Grund und Zusatzversicherung Tips",
    url: "https://www.youtube.com/embed/2Nx8CGWBBhc?si=htSUceRAbiKQNJng",
    topic: "General Relocation",
  },
  {
    id: 5,
    title: "Navigating Local Laws",
    url: "https://www.youtube.com/embed/CSDx57gd2Qs?si=nYfybbUT2Y0YIYPl",
    topic: "General Relocation",
  },
  {
    id: 6,
    title: "Umzugsgut in die Schweiz einführen",
    url: "https://www.youtube.com/embed/aNHkylBfUfs?si=Rvfq8MLNs0zJ87xb",
    topic: "Pets",
  },
  {
    id: 7,
    title: "Katzen in die Schweiz einführen",
    url: "https://www.youtube.com/embed/H_aB1Xa0VaY?si=vgjL26Bin_jfxvlJ", // original CSDx57gd2Qs?si=nYfybbUT2Y0YIYPl
    topic: "Pets",
  },
  {
    id: 8,
    title: "Hunde in die Schweiz einführen",
    url: "https://www.youtube.com/embed/Hm0n7Bnhals?si=6eVlFFZAnVs3L-eT",
    topic: "Pets",
  },
  {
    id: 9,
    title: "Öffentlicher Nahverkehr in der Schweiz",
    url: "https://www.youtube.com/embed/U_FRKGpgxD8?si=P98baV7RcRC9QK-S",
    topic: "Public Transport",
  },
  {
    id: 10,
    title: "Cantonal differences for Apartments",
    url: "https://www.youtube.com/embed/CSDx57gd2Qs?si=nYfybbUT2Y0YIYPl",
    topic: "Apartment Search",
  },
  {
    id: 11,
    title: "Relotu: Diplomanerkennung von Pflegenden & anderen medizinischen Berufen",
    url: "https://www.youtube.com/embed/83heKqLrCxQ?si=eLI3xBKygs9IEti-",
    topic: "Diploma Recognition",
  },
  {
    id: 12,
    title: "Relotu: Diplomanerkennung others",
    url: "https://www.youtube.com/embed/83heKqLrCxQ?si=eLI3xBKygs9IEti-",
    topic: "Diploma Recognition",
  },
  // Agrega más videos según sea necesario
];

const VideoLibraryIndividuals: React.FC = () => {
  const { t } = useTranslation("global");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videosPerView, setVideosPerView] = useState(3);
  const [selectedTopic, setSelectedTopic] = useState<string>("All");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const isTransitioning = useRef(false);

  // Filtrar videos según el tema seleccionado
  const filteredVideos = selectedTopic === "All"
    ? videos
    : videos.filter(video => video.topic === selectedTopic);

  // Determinar el número real de videos por vista
  const actualVideosPerView = Math.min(videosPerView, filteredVideos.length);

  // Clonar los primeros y últimos videos para carrusel infinito solo si hay más videos que videosPerView
  const extendedVideos =
    filteredVideos.length > videosPerView
      ? [
          ...filteredVideos.slice(-actualVideosPerView),
          ...filteredVideos,
          ...filteredVideos.slice(0, actualVideosPerView),
        ]
      : [...filteredVideos];

  // Inicializar currentIndex al primer video real solo si hay más videos que videosPerView
  useEffect(() => {
    if (filteredVideos.length > videosPerView) {
      setCurrentIndex(actualVideosPerView);
      if (carouselRef.current) {
        carouselRef.current.style.transition = "none";
        carouselRef.current.style.transform = `translateX(-${actualVideosPerView * (100 / extendedVideos.length)}%)`;
      }
    } else {
      // Centrar los videos cuando no hay suficientes para el scroll infinito
      setCurrentIndex(0);
      if (carouselRef.current) {
        carouselRef.current.style.transition = "none";
        carouselRef.current.style.transform = `translateX(0%)`;
      }
    }
  }, [selectedTopic, videosPerView, extendedVideos.length, actualVideosPerView, filteredVideos.length]);

  // Manejar cambio de tamaño de pantalla para ajustar videosPerView
  useEffect(() => {
    const updateVideosPerView = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setVideosPerView(1);
      } else if (width >= 600 && width < 900) {
        setVideosPerView(2);
      } else {
        setVideosPerView(3);
      }
    };

    updateVideosPerView();
    window.addEventListener("resize", updateVideosPerView);
    return () => window.removeEventListener("resize", updateVideosPerView);
  }, []);

  // Manejar el fin de la transición para resetear el índice si es necesario
  const handleTransitionEnd = () => {
    if (!isTransitioning.current) return;

    if (filteredVideos.length > videosPerView) {
      if (currentIndex >= filteredVideos.length + actualVideosPerView) {
        // Llegó a los clones finales, resetear al inicio real
        setCurrentIndex(actualVideosPerView);
        if (carouselRef.current) {
          carouselRef.current.style.transition = "none";
          carouselRef.current.style.transform = `translateX(-${actualVideosPerView * (100 / extendedVideos.length)}%)`;
        }
      } else if (currentIndex < actualVideosPerView) {
        // Llegó a los clones iniciales, resetear al final real
        setCurrentIndex(filteredVideos.length + actualVideosPerView - 1);
        if (carouselRef.current) {
          carouselRef.current.style.transition = "none";
          carouselRef.current.style.transform = `translateX(-${(filteredVideos.length + actualVideosPerView - 1) * (100 / extendedVideos.length)}%)`;
        }
      }
    }
    isTransitioning.current = false;
  };

  // Manejar clic en flecha izquierda
  const handlePrev = () => {
    if (isTransitioning.current || filteredVideos.length <= 1) return;
    isTransitioning.current = true;
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
    if (carouselRef.current) {
      carouselRef.current.style.transition = "transform 0.5s ease-in-out";
      carouselRef.current.style.transform = `translateX(-${newIndex * (100 / extendedVideos.length)}%)`;
    }
  };

  // Manejar clic en flecha derecha
  const handleNext = () => {
    if (isTransitioning.current || filteredVideos.length <= 1) return;
    isTransitioning.current = true;
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    if (carouselRef.current) {
      carouselRef.current.style.transition = "transform 0.5s ease-in-out";
      carouselRef.current.style.transform = `translateX(-${newIndex * (100 / extendedVideos.length)}%)`;
    }
  };

  // Determinar si un video es el central para aplicar la clase 'active'
  const isCentralVideo = (index: number) => {
    return hoveredIndex !== null ? index === hoveredIndex : false;
  };

  return (
    <div className={styles.videoLibraryContainer}>
      <h2>{t('videoLibrary.header')}</h2>
      <h1>{t('videoLibrary.subHeader')}</h1>
      <p>{t('videoLibrary.intro')}</p>

      {/* Botones de Filtro */}
      <div className={styles.filterContainer}>
        {["All", "General Relocation", "Apartment Search", "Diploma Recognition", "Public Transport", "Pets"].map((topic) => (
          <button
            key={topic}
            className={`${styles.filterButton} ${selectedTopic === topic ? styles.activeFilter : ""}`}
            onClick={() => {
              setSelectedTopic(topic);
              setHoveredIndex(null); // Resetear hoveredIndex al cambiar filtro
            }}
          >
            {t(`videoLibrary.filters.${topic.replace(/ /g, '').toLowerCase()}`)}
          </button>
        ))}
      </div>

      {/* Carrusel */}
      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          <div className={styles.carouselWrapper}>
            <div
              className={styles.carouselTrack}
              ref={carouselRef}
              onTransitionEnd={handleTransitionEnd}
              style={{
                transform: filteredVideos.length > videosPerView
                  ? `translateX(-${currentIndex * (100 / extendedVideos.length)}%)`
                  : `translateX(0%)`,
                width: `${(extendedVideos.length * 100) / actualVideosPerView}%`,
                justifyContent: filteredVideos.length === 1 ? 'center' : 'flex-start', // Centrar si solo hay un video
              }}
              
            >
              {/* Video Cards */}
              {extendedVideos.map((video, index) => (
                <div
                  key={`${video.id}-${index}`}
                  className={`${styles.videoCard} ${isCentralVideo(index) ? styles.active : ""}`}
                  style={{ width: `${100 / extendedVideos.length}%` }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <iframe
                    src={video.url}
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={styles.videoIframe}
                    loading="lazy"
                  ></iframe>
                  <h3 className={styles.videoTitle}>{video.title}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Botones de Navegación */}
        {filteredVideos.length > 1 && (
          <div className={styles.navButtonsContainer}>
            <button
              onClick={handlePrev}
              className={`${styles.navButton} ${styles.navButtonPrev}`}
              aria-label={t('videoLibrary.previousVideos')}
            >
              <LeftArrow />
            </button>
            <button
              onClick={handleNext}
              className={`${styles.navButton} ${styles.navButtonNext}`}
              aria-label={t('videoLibrary.nextVideos')}
            >
              <RightArrow />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoLibraryIndividuals;