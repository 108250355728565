import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext';

interface Company {
  _id: string;
  name: string;
  email: string;
  avatar?: string;
  // Otros campos según tu modelo
}

const CompanyProfile: React.FC = () => {
  const { t } = useTranslation("global");
  const { token } = useContext(AuthContext);

  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Obtener el token de acceso de manera segura

  useEffect(() => {
    const fetchCompany = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch("https://api.relotu.com/companies", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Error fetching company data");
        }

        const data: Company = await response.json();
        setCompany(data);
      } catch (error: any) {
        setError(error.message || "Unexpected error");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchCompany();
    } else {
      setError("No access token found");
      setIsLoading(false);
    }
  }, [token]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!company) {
    return <div>No se encontraron datos de la empresa</div>;
  }

  return (
    <div>
      <h1>{company.name}</h1>
      {company.avatar && (
        <img
          src={company.avatar}
          alt={`${company.name} Avatar`}
          style={{ width: "200px", height: "200px", objectFit: "cover" }}
        />
      )}
      <p>Email: {company.email}</p>
      {/* Muestra otros datos de la empresa según sea necesario */}
    </div>
  );
};

export default CompanyProfile;
