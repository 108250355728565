// src/components/Authentication components/RecoverPasswordRequest.tsx

import React, { useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/Authentication styles/LogIn.module.css"; // Reutiliza los estilos de Login
import recoverRequestImage from "../../assets/recoverPassword.png"; // Asegúrate de tener esta imagen
import { useTranslation } from "react-i18next";

// Import FontAwesomeIcon y los íconos necesarios
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const RecoverPasswordRequest: React.FC = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  // Estados para manejar el email, tipo de usuario, y mensajes
  const [email, setEmail] = useState<string>("");
  const [userType, setUserType] = useState<"company" | "mover">("mover");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Manejar cambios en las entradas
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedType = event.target.value as "company" | "mover";
    setUserType(selectedType);
  };

  // Validar formato de email
  const validateEmail = (email: string): boolean => {
    // Expresión regular simple para validar email
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Manejar el envío del formulario
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);

    // Validar que el email sea válido
    if (!validateEmail(email)) {
      setErrorMessage(t("authentication.recover-request-invalid-email"));
      return;
    }

    setLoading(true);

    try {
      // Enviar solicitud al backend para iniciar la recuperación de contraseña
      const response = await fetch("https://api.relotu.com/auth/recover-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          type: userType,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Recover password request successful:", data);
        setSuccessMessage(t("authentication.recover-request-success"));
        alert(t("authentication.recover-request-success-alert"));

          navigate("/email-sent-confirmation");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || t("authentication.recover-request-failed"));
      }
    } catch (error) {
      console.error("Error during recover password request:", error);
      setErrorMessage(t("authentication.recover-request-error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className={styles.title}>
        {t("authentication.recover-request-title")}{" "}
        <span role="img" aria-label="recover" className={styles.icon}>
          🔄
        </span>
      </h2>
      <div className={styles.loginPageContainer}>
        <img
          src={recoverRequestImage}
          alt="Recover Password Request"
          className={styles.loginImage}
        />
        <div className={styles.loginContainer}>
          <h4 className={styles.subtitle}>
            {t("authentication.recover-request-subtitle")}
          </h4>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                {t("authentication.recover-request-email")}

              </label>
              <div className={styles.inputWrapper}>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  disabled={loading}
                  className={styles.input}
                  placeholder={t("authentication.recover-request-email-placeholder")}
                />
              </div>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="userType" className={styles.label}>
                {t("authentication.recover-request-user-type")}
              </label>
              <div className={styles.inputWrapper}>
                <select
                  id="userType"
                  value={userType}
                  onChange={handleUserTypeChange}
                  required
                  disabled={loading}
                  className={styles.input}
                >
                  <option value="mover">{t("authentication.user-type-mover")}</option>
                  <option value="company">{t("authentication.user-type-company")}</option>
                </select>
              </div>
            </div>

            {errorMessage && <p className={styles.error}>{errorMessage}</p>}

            {successMessage && <p className={styles.success}>{successMessage}</p>}

            <button
              className={styles.loginButton}
              type="submit"
              disabled={loading}
            >
              {loading
                ? t("authentication.recover-request-sending")
                : t("authentication.recover-request-button")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecoverPasswordRequest;
