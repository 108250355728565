// src/components/CreateLinks.tsx

import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faUserGraduate,
  faBus,
  faWalking,
  faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Dashboard styles/CreateLinks.module.css";
import { useTranslation } from "react-i18next";
// Importa AuthContext para acceder al token de autenticación
import { AuthContext } from "../../context/AuthContext";

const CreateLinks: React.FC = () => {
  const { t } = useTranslation("global");
  const { token, isAuthenticated } = useContext(AuthContext); // Asumiendo que AuthContext proporciona 'token' y 'isAuthenticated'
  
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Lista de tipos de usuario
  const userTypes = [
    {
      name: t("servicesCompanies.provideRelocationService.interested"),
      icon: faUserPlus,
      moveType: "interested",
    },
    {
      name: t("servicesCompanies.provideRelocationService.candidate"),
      icon: faUserGraduate,
      moveType: "candidate",
    },
    {
      name: t("servicesCompanies.provideRelocationService.mover"),
      icon: faTruckMoving,
      moveType: "mover",
    },
    {
      name: t("servicesCompanies.provideRelocationService.dailyCommuter"),
      icon: faWalking,
      moveType: "daily-commuter",
    },
    {
      name: t("servicesCompanies.provideRelocationService.weeklyCommuter"),
      icon: faBus,
      moveType: "weekly-commuter",
    },
  ];

  // Función para obtener el companyId
  const fetchCompanyId = async () => {
    if (!token) {
      setError("No se encontró el token de autenticación.");
      setLoading(false);
      return;
    }

    const endpoint = "https://api.relotu.com/companies";

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-relotu-access": token,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // Asumiendo que la respuesta es un objeto con un campo '_id'
      // Si la respuesta es una lista, ajusta según sea necesario
      const fetchedCompanyId = Array.isArray(data) ? data[0]?._id : data._id;

      if (fetchedCompanyId) {
        setCompanyId(fetchedCompanyId);
      } else {
        throw new Error("No se encontró el ID de la empresa en la respuesta.");
      }
    } catch (err: any) {
      setError(err.message || "Ocurrió un error al obtener el ID de la empresa.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchCompanyId();
    } else {
      setLoading(false);
      setError("Usuario no autenticado.");
    }
  }, [isAuthenticated]);

  const handleCopy = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      alert(t("servicesCompanies.provideRelocationService.linkCopied"));
    }).catch(() => {
      alert(t("servicesCompanies.provideRelocationService.copyFailed"));
    });
  };

  if (loading) {
    return (
      <section className={styles.section}>
        <p className={styles.title}>{t("servicesCompanies.provideRelocationService.title")}</p>
        <p>{t("servicesCompanies.provideRelocationService.loading")}</p>
      </section>
    );
  }

  if (error) {
    return (
      <section className={styles.section}>
        <p className={styles.title}>{t("servicesCompanies.provideRelocationService.title")}</p>
        <p className={styles.error}>{error}</p>
      </section>
    );
  }

  if (!companyId) {
    return (
      <section className={styles.section}>
        <p className={styles.title}>{t("servicesCompanies.provideRelocationService.title")}</p>
        <p className={styles.error}>{t("servicesCompanies.provideRelocationService.noCompanyId")}</p>
      </section>
    );
  }

  return (
    <section className={styles.section}>
      <p className={styles.title}>{t("servicesCompanies.provideRelocationService.title")}</p>
      <div className={styles.buttonContainer}>
        {userTypes.map((userType) => (
          <button
            key={userType.moveType}
            className={styles.button}
            onClick={() =>
              handleCopy(
                `http://relotu.com/registration-individuals?companyId=${companyId}&moveType=${userType.moveType}`
              )
            }
          >
            <FontAwesomeIcon icon={userType.icon} className={styles.icon} />
            <span className={styles.text}>{userType.name}</span>
          </button>
        ))}
      </div>
    </section>
  );
};

export default CreateLinks;
