// ProtectedPage.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Authentication styles/ProtectedPage.module.css";
import { useTranslation } from "react-i18next";

export const ProtectedPage: React.FC<{
  onProtectedPage: () => void;
  notOnProtectedPage: () => void;
}> = ({ onProtectedPage, notOnProtectedPage }) => {
  const [t, i18n] = useTranslation("global");

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    onProtectedPage();
    return () => {
      notOnProtectedPage();
    };
  }, [onProtectedPage, notOnProtectedPage]);

  const [showChatButton, setShowChatButton] = useState(true);

  const correctPasswords = ["Welcome2Switzerland!Test"];

  const handleLoginMover = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (correctPasswords.includes(password)) {
      localStorage.setItem("isPassword", "true");
      navigate("/registration-organization");
    } else {
      setIsPasswordValid(false);
    }
  };

  return (
    <div className={styles.protectedPageContainer}>
      <img src={logo} alt="Logo" className={styles.logoProtectedPage} />
      <p> Please note that this password is requested due to new updates that are being deployed, please contact us on info@relotu.com to use our services.</p>

      <form onSubmit={handleLoginMover}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t("password.password-placeholder")}
          className={styles.passwordInput}
        />
        <button type="submit" className={styles.protectedPageButton}>
          <FontAwesomeIcon
            icon={faSignInAlt}
            className={styles.protectedPageIcon}
          />
          {t("password.password-button")}{" "}
        </button>
      </form>
      {!isPasswordValid && (
        <p className={styles.errorMessage}>{t("password.password-error")}</p>
      )}
    </div>
  );
};

