// src/components/Authentication components/RecoverPassword.tsx

import React, { useState, ChangeEvent, FormEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/Authentication styles/LogIn.module.css"; // Reutiliza los estilos de Login
import recoverPasswordImage from "../../assets/recoverPassword.png"; // Asegúrate de tener esta imagen
import { useTranslation } from "react-i18next";
import PasswordValidation from "./ValidationInputs"; // Ensure this component is properly imported

// Import FontAwesomeIcon y los íconos necesarios
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const ChangePassword: React.FC = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();

  // Estados para manejar las contraseñas y visibilidad
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Validation and error states
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  // Extraer el token de los parámetros de consulta
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  // Manejar cambios en las entradas de contraseña
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  // Función para alternar la visibilidad de la contraseña
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Validar formato de contraseña (puedes ajustar esta función según tus necesidades)
  const validatePassword = (pwd: string): boolean => {
    // Ejemplo: Al menos 8 caracteres, una letra mayúscula, una minúscula y un número
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return re.test(pwd);
  };

  // Manejar el envío del formulario
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);

    // Validar que el token exista
    if (!token) {
      setErrorMessage(t("authentication.recover-password-no-token"));
      return;
    }

    // Validar que las contraseñas no estén vacías
    if (!password || !confirmPassword) {
      setErrorMessage(t("authentication.recover-password-fill-all-fields"));
      return;
    }

    // Validar que las contraseñas coincidan
    if (password !== confirmPassword) {
      setErrorMessage(t("authentication.recover-password-passwords-mismatch"));
      return;
    }

    // Validar formato de contraseña
    if (!validatePassword(password)) {
      setErrorMessage(t("authentication.recover-password-invalid-format"));
      return;
    }

    setLoading(true);

    try {
      // Enviar solicitud al backend para cambiar la contraseña
      const response = await fetch(
        "https://api.relotu.com/auth/change-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            password: password,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Password reset successful:", data);
        setSuccessMessage(t("authentication.recover-password-success"));
        alert(t("authentication.recover-password-success-alert"));

          navigate("/login-individuals");
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || t("authentication.recover-password-failed")
        );
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setErrorMessage(t("authentication.recover-password-error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className={styles.title}>
        {t("authentication.recover-password-title")}{" "}
        <span role="img" aria-label="key" className={styles.icon}>
          🔑
        </span>
      </h2>
      <div className={styles.loginPageContainer}>
        <img
          src={recoverPasswordImage}
          alt="Recover Password"
          className={styles.loginImage}
        />
        <div className={styles.loginContainer}>
          <h4 className={styles.subtitle}>
            {t("authentication.recover-password-subtitle")}
          </h4>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.label}>
                {t("authentication.recover-password-new-password")}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  disabled={loading}
                  className={styles.input}
                  placeholder={t(
                    "authentication.recover-password-new-password-placeholder"
                  )}
                />
                <button
                  type="button"
                  className={styles.eyeButton}
                  onClick={toggleShowPassword}
                  aria-label={
                    showPassword
                      ? t("authentication.hide-password")
                      : t("authentication.show-password")
                  }
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              {/* Password Validation Component */}
              <PasswordValidation password={password} />
              {fieldErrors.password && (
                <p className={styles.error}>{fieldErrors.password}</p>
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="confirmPassword" className={styles.label}>
                {t("authentication.recover-password-confirm-password")}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  disabled={loading}
                  className={styles.input}
                  placeholder={t(
                    "authentication.recover-password-confirm-password-placeholder"
                  )}
                />
                <button
                  type="button"
                  className={styles.eyeButton}
                  onClick={toggleShowPassword}
                  aria-label={
                    showPassword
                      ? t("authentication.hide-password")
                      : t("authentication.show-password")
                  }
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            {/* Mostrar mensaje de error si ocurre alguno */}
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}

            {/* Mostrar mensaje de éxito */}
            {successMessage && (
              <p className={styles.success}>{successMessage}</p>
            )}

            <button
              className={styles.loginButton}
              type="submit"
              disabled={loading}
            >
              {loading
                ? t("authentication.recover-password-resetting")
                : t("authentication.recover-password-reset-button")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
