import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/Authentication styles/MultiStepSignUp.module.css";
import MovingDetails from "./MovingDetails";
import MovingSelectionForm from "./MovingSelectionForm";
import { useTranslation } from "react-i18next";
import PasswordValidation from "./ValidationInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface moveInfo {
  moveType: string;
  from: string;
  to: string;
  moveAt?: string;
  isDateUnknown: boolean;
  moveWithPartner?: boolean;
  moveWithPets?: boolean;
  moveWithKids?: boolean;
  moveWithCar?: boolean;
  isEuCitizen?: boolean;
}

interface FormData {
  companyId: string;
  name: string;
  lastName: string;
  email: string;
  password: string;
  moveInfo: moveInfo;
}

const MultiStepSignUp: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyIdFromUrl = searchParams.get("companyId");
  const moveTypeFromUrl = searchParams.get("moveType");

  const [formData, setFormData] = useState<FormData>({
    companyId: companyIdFromUrl || "",
    name: "",
    lastName: "",
    email: "",
    password: "",
    moveInfo: {
      moveType: moveTypeFromUrl || "",
      from: "",
      to: "",
      moveAt: "",
      isDateUnknown: false,
      moveWithPartner: false,
      moveWithPets: false,
      moveWithKids: false,
      moveWithCar: false,
      isEuCitizen: false,
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= minLength;

    return (
      isValidLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleMovingChange = (field: keyof moveInfo, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      moveInfo: {
        ...prevData.moveInfo,
        [field]: value,
      },
    }));
  };

  const nextStep = () => {
    let isValid = true;
    let errors: { [key: string]: string } = {};

    if (step === 1) {
      if (!formData.name.trim()) {
        errors.name = t("authentication.error-required-field");
        isValid = false;
      }
      if (!formData.lastName.trim()) {
        errors.lastName = t("authentication.error-required-field");
        isValid = false;
      }
      if (!formData.email.trim()) {
        errors.email = t("authentication.error-required-field");
        isValid = false;
      } else if (!validateEmail(formData.email)) {
        errors.email = t("authentication.error-invalid-email");
        isValid = false;
      }
      if (!formData.password.trim()) {
        errors.password = t("authentication.error-required-field");
        isValid = false;
      } else if (!validatePassword(formData.password)) {
        errors.password = t("authentication.error-invalid-password");
        isValid = false;
      }
      if (!confirmPassword.trim()) {
        errors.confirmPassword = t("authentication.error-required-field");
        isValid = false;
      }
      if (formData.password !== confirmPassword) {
        errors.confirmPassword = t("authentication.error-password-mismatch");
        isValid = false;
      }
    }

    if (step === 2) {
      if (!formData.moveInfo.from.trim()) {
        errors.from = t("authentication.error-required-field");
        isValid = false;
      }
      if (!formData.moveInfo.to.trim()) {
        errors.to = t("authentication.error-required-field");
        isValid = false;
      }
      if (
        !formData.moveInfo.isDateUnknown &&
        (!formData.moveInfo.moveAt || !formData.moveInfo.moveAt.trim())
      ) {
        errors.moveAt = t("authentication.error-required-field");
        isValid = false;
      }
    }

    setFieldErrors(errors);

    if (isValid) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (formData.moveInfo.isDateUnknown) {
      delete formData.moveInfo.moveAt;
    }

    try {
      const response = await fetch("https://api.relotu.com/individuals", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      console.log(formData);

      if (!response.ok) {
        throw new Error("Failed to sign up. Please try again.");
      }

      const result = await response.json();

      localStorage.setItem("userType", "individual");

      navigate("/email-sent-confirmation");

      alert("Sign up successful!");
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>
        {t("authentication.registration-user-title")}
        <span role="img" aria-label="globe" className={styles.icon}>
          ✨
        </span>
      </h2>
      <h4 className={styles.subtitle}>
        {t("authentication.registration-user-subtitle")}{" "}
        <Link to="/registration-organization" className={styles.link}>
          {t("authentication.registration-not-individual-link")}
        </Link>
      </h4>

      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <h2 className={styles.cardTitle}>
            {t("authentication.registration-user-step")} {step}{" "}
            {t("authentication.registration-user-step2")}
          </h2>
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{
                width: `${step === 1 ? "0%" : step === 2 ? "50%" : "100%"}`,
              }}
            ></div>
            <div className={styles.stepMarkers}>
              <span
                className={`${styles.step} ${step >= 1 ? styles.active : ""}`}
              >
                1
              </span>
              <span
                className={`${styles.step} ${step >= 2 ? styles.active : ""}`}
              >
                2
              </span>
              <span
                className={`${styles.step} ${step === 3 ? styles.active : ""}`}
              >
                3
              </span>
            </div>
          </div>
        </div>

        <div className={styles.cardContent}>
          {step === 3 ? (
            <form onSubmit={handleSubmit}>
              <MovingSelectionForm onDetailChange={handleMovingChange} />
              {error && <p className={styles.error}>{error}</p>}
              {loading && <p className={styles.loading}>Submitting...</p>}
              <div className={styles.cardFooter}>
                <button
                  type="button"
                  onClick={prevStep}
                  className={`${styles.button} ${styles.buttonSecondary}`}
                >
                  {t("authentication.registration-user-previous")}
                </button>
                <button
                  type="submit"
                  className={`${styles.button} ${styles.buttonPrimary}`}
                >
                  {t("authentication.registration-user-button")}
                </button>
              </div>
            </form>
          ) : (
            <>
              {step === 1 && (
                <>
                  <div className={styles.inputGroup}>
                    <input
                      type="hidden"
                      id="companyId"
                      name="companyId"
                      value={formData.companyId}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="name" className={styles.label}>
                      {t("authentication.registration-user-name")}
                    </label>
                    <input
                      id="name"
                      name="name"
                      placeholder="e.g. yourname"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          name: e.target.value,
                        }))
                      }
                      required
                      className={styles.input}
                    />
                    {fieldErrors.name && (
                      <p className={styles.error}>{fieldErrors.name}</p>
                    )}
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="lastName" className={styles.label}>
                      {t("authentication.registration-user-lastName")}
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      placeholder="e.g. lastname"
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          lastName: e.target.value,
                        }))
                      }
                      required
                      className={styles.input}
                    />
                    {fieldErrors.lastName && (
                      <p className={styles.error}>{fieldErrors.lastName}</p>
                    )}
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="email" className={styles.label}>
                      {t("authentication.registration-user-email")}
                    </label>
                    <input
                      id="email"
                      name="email"
                      placeholder="e.g. yourname@xyz.com"
                      type="email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          email: e.target.value,
                        }))
                      }
                      required
                      className={styles.input}
                    />
                    {fieldErrors.email && (
                      <p className={styles.error}>{fieldErrors.email}</p>
                    )}
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="password" className={styles.label}>
                      {t("authentication.registration-user-password")}
                    </label>
                    <div className={styles.inputWrapper}>
                      <input
                        id="password"
                        name="password"
                        placeholder="**********"
                        type={showPassword ? "text" : "password"}
                        value={formData.password}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            password: e.target.value,
                          }))
                        }
                        required
                        className={styles.input}
                      />
                      <button
                        type="button"
                        className={styles.eyeButton}
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label={
                          showPassword
                            ? t("authentication.hide-password")
                            : t("authentication.show-password")
                        }
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    <PasswordValidation password={formData.password} />
                    {fieldErrors.password && (
                      <p className={styles.error}>{fieldErrors.password}</p>
                    )}
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="confirmPassword" className={styles.label}>
                      {t("authentication.registration-user-confirm-password")}
                    </label>
                    <div className={styles.inputWrapper}>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="**********"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className={styles.input}
                      />
                      <button
                        type="button"
                        className={styles.eyeButton}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        aria-label={
                          showConfirmPassword
                            ? t("authentication.hide-password")
                            : t("authentication.show-password")
                        }
                      >
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {fieldErrors.confirmPassword && (
                      <p className={styles.error}>
                        {fieldErrors.confirmPassword}
                      </p>
                    )}
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <MovingDetails
                    moveType={formData.moveInfo.moveType}
                    from={formData.moveInfo.from}
                    to={formData.moveInfo.to}
                    approxDate={formData.moveInfo.moveAt || ""}
                    isDateUnknown={formData.moveInfo.isDateUnknown}
                    onFromChange={(value) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        moveInfo: { ...prevData.moveInfo, from: value },
                      }))
                    }
                    onToChange={(value) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        moveInfo: { ...prevData.moveInfo, to: value },
                      }))
                    }
                    onApproxDateChange={(value) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        moveInfo: { ...prevData.moveInfo, moveAt: value },
                      }))
                    }
                    onIsDateUnknownChange={(value) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        moveInfo: {
                          ...prevData.moveInfo,
                          isDateUnknown: value,
                        },
                      }))
                    }
                    onSubmit={nextStep}
                    isMultiStep={true}
                  />
                  {fieldErrors.from && (
                    <p className={styles.error}>{fieldErrors.from}</p>
                  )}
                  {fieldErrors.to && (
                    <p className={styles.error}>{fieldErrors.to}</p>
                  )}
                  {fieldErrors.moveAt && (
                    <p className={styles.error}>{fieldErrors.moveAt}</p>
                  )}
                </>
              )}

              <div className={styles.cardFooter}>
                {step > 1 && (
                  <button
                    type="button"
                    onClick={prevStep}
                    className={`${styles.button} ${styles.buttonSecondary}`}
                  >
                    {t("authentication.registration-user-previous")}
                  </button>
                )}
                <button
                  type="button"
                  onClick={nextStep}
                  className={`${styles.button} ${styles.buttonPrimary}`}
                >
                  {t("authentication.registration-user-next")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiStepSignUp;
