// src/components/Dashboard components/DashboardCompanies.tsx

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../../styles/Dashboard styles/DashboardCompanies.module.css";
import CreateLinks from "./CreateLinks"; // Asegúrate de que la ruta sea correcta

interface Service {
  id: number;
  translationKey: string;
  videoUrls: {
    [language: string]: string;
  };
  pageLinks: string;
}

const services: Service[] = [
  {
    id: 1,
    translationKey: "servicesCompanies.provideRelocationService",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_PROVIDE_RELOCATION_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_PROVIDE_RELOCATION_ES",
    },
    pageLinks: "/company-provide-relocation",
  },
  {
    id: 2,
    translationKey: "servicesCompanies.peopleInsights",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_PEOPLE_INSIGHTS_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_PEOPLE_INSIGHTS_ES",
    },
    pageLinks: "/company-people-insights",
  },
  {
    id: 3,
    translationKey: "servicesCompanies.yourTasks",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_YOUR_TASKS_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_YOUR_TASKS_ES",
    },
    pageLinks: "/company-your-tasks",
  },
  /*{
    id: 4,
    translationKey: "servicesCompanies.personalizeRelotu",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_PERSONALIZE_RELOTU_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_PERSONALIZE_RELOTU_ES",
    },
    pageLinks: "/company-personalize-relotu",
  },*/
  {
    id: 5,
    translationKey: "servicesCompanies.videoLibrary",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_VIDEO_LIBRARY_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_VIDEO_LIBRARY_ES",
    },
    pageLinks: "/company-videolibrary",
  },
  {
    id: 6,
    translationKey: "servicesCompanies.contactUs",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_CONTACT_US_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_CONTACT_US_ES",
    },
    pageLinks: "/company-contact-us",
  },
  {
    id: 7,
    translationKey: "servicesCompanies.whatIsRelotu",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_WHAT_IS_RELOTU_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_WHAT_IS_RELOTU_ES",
    },
    pageLinks: "/company-what-is-relotu",
  },
  /*{
    id: 8,
    translationKey: "servicesCompanies.billing",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_BILLING_EN",
      es: "https://www.youtube.com/embed/VIDEO_ID_BILLING_ES",
    },
    pageLinks: "/company-billing",
  },*/
];

const DashboardCompanies: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const [selectedService, setSelectedService] = useState<string | null>(null);

  const handleServiceClick = (translationKey: string) => {
    setSelectedService(translationKey);
  };

  const selectedServiceObj = services.find(
    (service) => service.translationKey === selectedService
  );

  const getVideoUrl = (): string => {
    if (!selectedServiceObj) return "";
    const currentLanguage = i18n.language.startsWith("es") ? "es" : "en";
    return selectedServiceObj.videoUrls[currentLanguage] || selectedServiceObj.videoUrls["en"];
  };

  const getPageLink = (): string => {
    if (!selectedServiceObj) return "/";
    return selectedServiceObj.pageLinks;
  };

  return (
    <div className={styles.componentServices}>
      <h2>{t("servicesCompanies.header")}</h2>
      <h1
        dangerouslySetInnerHTML={{
          __html: t("servicesCompanies.subHeader"),
        }}
      />
      <p>{t("servicesCompanies.intro")}</p>
      <div className={styles.containerServices}>
        <div className={styles.servicesList}>
          {services.map((service) => (
            <div
              key={service.id}
              className={`${styles.serviceBox} ${
                selectedService === service.translationKey ? styles.active : ""
              }`}
              onClick={() => handleServiceClick(service.translationKey)}
              role="button"
              aria-pressed={selectedService === service.translationKey}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleServiceClick(service.translationKey);
              }}
            >
              <div>
                <h3>{t(`${service.translationKey}.name`)}</h3>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.serviceDetails}>
          {selectedService ? (
            <>
              <h2>{t(`${selectedService}.name`)}</h2>
              <p>{t(`${selectedService}.details`)}</p>

              {/* Renderizar CreateLinks antes del video para Provide Relocation Service */}
              {selectedService === "servicesCompanies.provideRelocationService" && (
                <CreateLinks />
              )}

              <div className={styles.serviceVideo}>
                <iframe
                  src={getVideoUrl()}
                  title={t(`${selectedService}.name`)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className={styles.videoIframe}
                  loading="lazy"
                ></iframe>
              </div>

              {selectedService !== "servicesCompanies.whatIsRelotu" && (
                <div className={styles.serviceButton}>
                  {selectedService === "servicesCompanies.provideRelocationService" ? (
                    // Ya no renderizamos el botón, ya que usamos CreateLinks
                    null
                  ) : (
                    <Link to={getPageLink()} className={styles.linkButton}>
                      {t(`${selectedService}.button`)}
                    </Link>
                  )}
                </div>
              )}
            </>
          ) : (
            <p>{t("servicesCompanies.selectService")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardCompanies;
