// IndividualsVideoText.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/Individuals styles/IndividualsVideoText.module.css";
import {
  faLaptopCode,
  faSuitcase,
  faHome,
  faThumbsUp,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const IndividualsVideoText = () => {
  const [t, i18n] = useTranslation("global");
  const videoLink =
  i18n.language === "de" ? "KV_6vA9MRo4?si=q4WBxgyLZRO9krFA" : "KV_6vA9MRo4?si=q4WBxgyLZRO9krFA";

  return (
    <div className={styles.videoTextContainer}>
      <div className={styles.videoContainer}>
        <iframe
          src={`https://www.youtube.com/embed/${videoLink}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.textContainer}>
        <h1>
          {t("individuals.text-video-title-black")}{" "}
          <span className={styles.highlighted}>
            {t("individuals.text-video-title-blue")}{" "}
          </span>
          {t("individuals.text-video-title-black2")}
        </h1>
        <p>{t("individuals.text-video-paragraph")}</p>
        <ul>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faLaptopCode} />{" "}
            <span>{t("individuals.text-video-list1-bold")}</span>{" "}
            {t("individuals.text-video-list1-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faSuitcase} />{" "}
            <span>{t("individuals.text-video-list2-bold")} </span>{" "}
            {t("individuals.text-video-list2-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faThumbsUp} />{" "}
            <span>{t("individuals.text-video-list3-bold")}</span>{" "}
            {t("individuals.text-video-list3-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faHome} />{" "}
            <span>{t("individuals.text-video-list4-bold")}</span>{" "}
            {t("individuals.text-video-list4-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faGift} />{" "}
            <span>{t("individuals.text-video-list5-bold")}</span>{" "}
            {t("individuals.text-video-list5-normal")}
          </p>
        </ul>
      </div>
    </div>
  );
};

export default IndividualsVideoText;
