// src/components/DashboardIndividuals.tsx

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../../styles/Dashboard styles/DashboardIndividuals.module.css";
// src/components/DashboardIndividuals.tsx

interface Service {
  id: number;
  translationKey: string;
  videoUrls: {
    [language: string]: string;
  };
  pageLinks: string;
  isDisabled?: (userMoveType: string | null) => boolean;
}

const services: Service[] = [
  {
    id: 1,
    translationKey: "servicesIndividuals.chatbot",
    videoUrls: {
      en: "https://www.youtube.com/embed/S-EhPzxEcSk?si=OHsl0JgOZflA7eLc",
      es: "https://www.youtube.com/embed/S-EhPzxEcSk?si=OHsl0JgOZflA7eLc",
    },
    pageLinks: "/chat",
  },
  {
    id: 2,
    translationKey: "servicesIndividuals.personalAssistance",
    videoUrls: {
      en: "https://www.youtube.com/embed/-mCgZnMpPNI?si=s6CH0oyVqvQo87bk",
      es: "https://www.youtube.com/embed/-mCgZnMpPNI?si=s6CH0oyVqvQo87bk",
    },
    pageLinks: "/my-tasks",
    isDisabled: (userMoveType) => userMoveType === "interested",
  },
  {
    id: 3,
    translationKey: "servicesIndividuals.videoLibrary",
    videoUrls: {
      en: "https://www.youtube.com/embed/Vx3ZnTzVie0?si=T2t9k75E6rEOq7y1",
      es: "https://www.youtube.com/embed/Vx3ZnTzVie0?si=T2t9k75E6rEOq7y1",
    },
    pageLinks: "/videolibrary",
  },
  {
    id: 4,
    translationKey: "servicesIndividuals.contactUs",
    videoUrls: {
      en: "https://www.youtube.com/embed/kWIR4fR_8os?si=huuLh-DSrXelhHEq",
      es: "https://www.youtube.com/embed/kWIR4fR_8os?si=jnCcsd95MSL4GOeR",
    },
    pageLinks: "/contact-us",
  },
  {
    id: 5,
    translationKey: "servicesIndividuals.whatIsRelotu",
    videoUrls: {
      en: "https://www.youtube.com/embed/A3jB11uyAzk?si=wyUrzwu1QY5lpNdl",
      es: "https://www.youtube.com/embed/A3jB11uyAzk?si=wyUrzwu1QY5lpNdl",
    },
    pageLinks: "/what-is-relocation",
  },
];

const DashboardIndividuals: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const { isAuthenticated } = useContext(AuthContext);

  const [userMoveType, setUserMoveType] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await fetch("https://api.relotu.com/individuals", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Fetched individual data:", data);
            setUserMoveType(data.moveInfo.moveType);
          } else {
            console.error("Error fetching individual data");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  const handleServiceClick = (translationKey: string) => {
    setSelectedService(translationKey);
  };

  const selectedServiceObj = services.find(
    (service) => service.translationKey === selectedService
  );

  const getVideoUrl = (): string => {
    if (!selectedServiceObj) return "";
    const currentLanguage = i18n.language.startsWith("es") ? "es" : "en";
    return (
      selectedServiceObj.videoUrls[currentLanguage] ||
      selectedServiceObj.videoUrls["en"]
    );
  };

  const getPageLink = (): string => {
    if (!selectedServiceObj) return "/";
    return selectedServiceObj.pageLinks;
  };

  return (
    <div className={styles.componentServices}>
      <h2>{t("servicesIndividuals.header")}</h2>
      <h1
        dangerouslySetInnerHTML={{
          __html: t("servicesIndividuals.subHeader"),
        }}
      />
      <p>{t("servicesIndividuals.intro")}</p>
      <div className={styles.containerServices}>
        <div className={styles.servicesList}>
          {services.map((service) => (
            <div
              key={service.id}
              className={`${styles.serviceBox} ${
                selectedService === service.translationKey ? styles.active : ""
              }`}
              onClick={() => handleServiceClick(service.translationKey)}
              role="button"
              aria-pressed={selectedService === service.translationKey}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter")
                  handleServiceClick(service.translationKey);
              }}
            >
              <div>
                <h3>{t(`${service.translationKey}.name`)}</h3>
                <p>{t(`${service.translationKey}.description`)}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.serviceDetails}>
          {selectedService ? (
            <>
              <h2>{t(`${selectedService}.name`)}</h2>
              <p>{t(`${selectedService}.details`)}</p>

              <div className={styles.serviceVideo}>
                <iframe
                  src={getVideoUrl()}
                  title={t(`${selectedService}.name`)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className={styles.videoIframe}
                  loading="lazy"
                ></iframe>
              </div>

              {selectedService !== "servicesIndividuals.whatIsRelotu" && (
                <div className={styles.serviceButton}>
                  {selectedService ===
                    "servicesIndividuals.personalAssistance" &&
                  userMoveType === "interested" ? (
                    <span className={styles.disabledButtonContainer}>
                      <span className={styles.disabledButton}>
                        {t(`${selectedService}.button`)}
                        <FontAwesomeIcon
                          icon={faLock}
                          className={styles.lockIcon}
                        />
                      </span>
                      <span className={styles.tooltipText}>
                        {t("servicesIndividuals.tooltip-disabled-service")}
                      </span>
                    </span>
                  ) : (
                    <Link to={getPageLink()} className={styles.linkButton}>
                      {t(`${selectedService}.button`)}
                    </Link>
                  )}
                </div>
              )}
            </>
          ) : (
            <p>{t("servicesIndividuals.selectService")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardIndividuals;
