import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/Tasks styles/TasksCompanies.module.css';
import placeholderImage from '../../assets/toDos.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faSquare,
  faToggleOff,
  faToggleOn,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '../ProgressBar';
import { groupNames, componentMap } from './TasksData';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext';

// Interfaces for typing
interface MoveInfo {
  from: string;
  to: string;
  moveAt: string;
  moveType: string;
  // ... other properties
}

interface UserTask {
  _id: string;
  title: string;
  isCompleted: boolean;
  group: string;
  component: string;
  // ... other properties
}

interface Individual {
  _id: string;
  name: string;
  lastName: string;
  moveInfo: MoveInfo;
  userTasks: UserTask[];
  // ... other properties
}

interface Task {
  id: string;
  title: string;
  description: JSX.Element;
  isCompleted: boolean;
  group: number;
}

const TasksCompanies: React.FC = () => {
  const { t } = useTranslation('global');
  const { token } = useContext(AuthContext);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [expandedGroup, setExpandedGroup] = useState<number | null>(null);
  const [expandedTask, setExpandedTask] = useState<string | null>(null);
  const [showCompletedGroups, setShowCompletedGroups] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // State for individuals and selection
  const [individuals, setIndividuals] = useState<Individual[]>([]);
  const [selectedIndividualId, setSelectedIndividualId] = useState<string | null>(null);
  const [expandedIndividualId, setExpandedIndividualId] = useState<string | null>(null);

  // State for expanded move types
  const [expandedMoveTypes, setExpandedMoveTypes] = useState<string[]>([]);

  useEffect(() => {
    // Fetch individuals and tasks
    const fetchTasks = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch('https://api.relotu.com/companies/user-tasks', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-relotu-access': token!,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error fetching tasks');
        }

        const data: Individual[] = await response.json();

        if (data.length === 0) {
          setIndividuals([]);
          setSelectedIndividualId(null);
          return;
        }

        setIndividuals(data);
        setSelectedIndividualId(data[0]._id);
      } catch (err: any) {
        setError(t('tasks.error-fetching-tasks'));
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchTasks();
    } else {
      setError(t('tasks.not-authenticated'));
    }
  }, [token, t]);

  // Update tasks when selectedIndividualId changes
  useEffect(() => {
    if (!selectedIndividualId || individuals.length === 0) {
      setTasks([]);
      return;
    }

    const individual = individuals.find((ind) => ind._id === selectedIndividualId);
    if (!individual) {
      setTasks([]);
      return;
    }

    const userTasks = individual.userTasks;

    const transformedTasks: Task[] = userTasks.map((backendTask) => {
      const DescriptionComponent = componentMap[backendTask.component];

      return {
        id: backendTask._id,
        title: backendTask.title,
        description: DescriptionComponent ? (
          <DescriptionComponent />
        ) : (
          <div>{t('tasks.no-description')}</div>
        ),
        isCompleted: backendTask.isCompleted,
        group: parseInt(backendTask.group, 10),
      };
    });

    setTasks(transformedTasks);
  }, [selectedIndividualId, individuals, t]);

  // Function to toggle task completion
  const toggleTaskCompleted = async (id: string) => {
    try {
      const taskToUpdate = tasks.find((task) => task.id === id);
      if (!taskToUpdate) {
        throw new Error('Task not found');
      }

      const newIsCompleted = !taskToUpdate.isCompleted;

      // Update tasks state
      const updatedTasks = tasks.map((task) =>
        task.id === id ? { ...task, isCompleted: newIsCompleted } : task
      );
      setTasks(updatedTasks);

      // Update individuals state for live update
      setIndividuals((prevIndividuals) =>
        prevIndividuals.map((individual) => {
          if (individual._id === selectedIndividualId) {
            return {
              ...individual,
              userTasks: individual.userTasks.map((task) =>
                task._id === id ? { ...task, isCompleted: newIsCompleted } : task
              ),
            };
          }
          return individual;
        })
      );

      // Send update to backend
      await fetch(`https://api.relotu.com/user-tasks/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-relotu-access': token!,
        },
        body: JSON.stringify({
          isCompleted: newIsCompleted,
        }),
      });
    } catch (err: any) {
      setError(t('tasks.error-updating-task'));
      // Revert changes in case of error
      const revertedTasks = tasks.map((task) =>
        task.id === id ? { ...task, isCompleted: !task.isCompleted } : task
      );
      setTasks(revertedTasks);
    }
  };

  const toggleExpandGroup = (group: number) => {
    setExpandedGroup((prevGroup) => (prevGroup === group ? null : group));
    setExpandedTask(null);
  };

  const toggleExpandTask = (id: string) => {
    setExpandedTask((prevTask) => (prevTask === id ? null : id));
  };

  const toggleExpandIndividual = (id: string) => {
    setExpandedIndividualId((prevId) => (prevId === id ? null : id));
  };

  const toggleExpandMoveType = (moveType: string) => {
    setExpandedMoveTypes((prevMoveTypes) =>
      prevMoveTypes.includes(moveType)
        ? prevMoveTypes.filter((type) => type !== moveType)
        : [...prevMoveTypes, moveType]
    );
  };

  const isGroupEmpty = (groupIndex: number) => {
    return tasks.filter((task) => task.group === groupIndex + 1).length === 0;
  };

  const isGroupCompleted = (group: number) => {
    const groupTasks = tasks.filter((task) => task.group === group);
    return groupTasks.length > 0 && groupTasks.every((task) => task.isCompleted);
  };

  // Calculate completion percentage
  const getCompletionPercentage = (individual: Individual) => {
    const totalTasks = individual.userTasks.length;
    const completedTasks = individual.userTasks.filter((task) => task.isCompleted).length;
    return totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
  };

  // Adjusted function to get completion color (excluding yellow)
  const getCompletionColor = (percentage: number) => {
    if (percentage <= 33) return 'red';
    if (percentage <= 66) return 'orange';
    return 'green';
  };

  // Check if all individuals are at 100% completion
  const allIndividualsCompleted =
    individuals.length > 0 &&
    individuals.every((individual) => getCompletionPercentage(individual) === 100);

  // Group individuals by moveType
  const groupedIndividuals = individuals.reduce((groups, individual) => {
    const moveType = individual.moveInfo.moveType || 'Unknown';
    const capitalizedMoveType = moveType.charAt(0).toUpperCase() + moveType.slice(1); // Capitalize moveType
    if (!groups[capitalizedMoveType]) {
      groups[capitalizedMoveType] = [];
    }
    groups[capitalizedMoveType].push(individual);
    return groups;
  }, {} as Record<string, Individual[]>);

  return (
    <div className={styles.container}>
      {/* Sidebar */}
      <div className={styles.sidebar}>
        <h2 className={styles.sidebarTitle}>{t('taks.title')}</h2>
        {individuals.length > 0 ? (
          Object.keys(groupedIndividuals).map((moveType) => (
            <div key={moveType} className={styles.moveTypeGroup}>
              <div
                className={styles.moveTypeHeader}
                onClick={() => toggleExpandMoveType(moveType)}
              >
                <span>{moveType}</span>
                <FontAwesomeIcon
                  icon={expandedMoveTypes.includes(moveType) ? faToggleOn : faToggleOff}
                  className={styles.moveTypeToggleIcon}
                />
              </div>
              {expandedMoveTypes.includes(moveType) && (
                <div className={styles.individualsList}>
                  {groupedIndividuals[moveType].map((individual) => {
                    const completionPercentage = getCompletionPercentage(individual);
                    const completionColor = getCompletionColor(completionPercentage);
                    return (
                      <div
                        key={individual._id}
                        className={`${styles.individualCard} ${
                          selectedIndividualId === individual._id ? styles.selectedCard : ''
                        }`}
                        onClick={() => {
                          setSelectedIndividualId(individual._id);
                          toggleExpandIndividual(individual._id);
                        }}
                      >
                        <div className={styles.cardFront}>
                          <h3>
                            {individual.name} {individual.lastName}
                          </h3>
                          <p style={{ color: completionColor }}>
                            {t('Completion')}: {completionPercentage}%
                          </p>
                        </div>
                        {expandedIndividualId === individual._id && (
                          <div className={styles.cardBack}>
                            <p>
                              <strong>{t('User Type')}:</strong> {individual.moveInfo.moveType}
                            </p>
                            <p>
                              <strong>{t('Move Date')}:</strong>{' '}
                              {new Date(individual.moveInfo.moveAt).toLocaleDateString()}
                            </p>
                            <p>
                              <strong>{t('From')}:</strong>{' '}
                              {individual.moveInfo.from.toUpperCase()}
                            </p>
                            <p>
                              <strong>{t('To')}:</strong> {individual.moveInfo.to.toUpperCase()}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className={styles.noIndividualsMessage}>{t('No users found.')}</div>
        )}
      </div>

      {/* Main content */}
      <div className={styles.mainContent}>
        {selectedIndividualId ? (
          tasks.length > 0 ? (
            <div className={styles.content}>
              <h1 className={styles.title}>{t('Tasks')}</h1>
              <p className={styles.subtitle}>
                {t('Here you can see the tasks assigned to the selected user.')}
              </p>

              <div className={styles.toggleButtonsContainer}>
                <button
                  className={`${styles.toggleButton} ${
                    showCompletedGroups ? styles.toggleOn : styles.toggleOff
                  }`}
                  onClick={() => setShowCompletedGroups((prev) => !prev)}
                >
                  <FontAwesomeIcon
                    icon={showCompletedGroups ? faToggleOn : faToggleOff}
                    className={styles.toggleIcon}
                  />
                  {showCompletedGroups
                    ? t('Hide Completed Groups')
                    : t('Show Completed Groups')}
                </button>
              </div>

              <ProgressBar
                totalTasks={tasks.length}
                completedTasks={tasks.filter((task) => task.isCompleted).length}
              />

              {isLoading && <p>{t('Loading tasks...')}</p>}
              {error && <p className={styles.error}>{error}</p>}

              <div className={styles.tasksList}>
                {groupNames.map((groupName, groupIndex) => {
                  const groupNumber = groupIndex + 1;
                  const isCurrentGroupCompleted = isGroupCompleted(groupNumber);

                  if (!showCompletedGroups && isCurrentGroupCompleted) {
                    return null;
                  }
                  if (isGroupEmpty(groupIndex)) {
                    return null;
                  }

                  const groupTasks = tasks.filter((task) => task.group === groupNumber);

                  return (
                    <div key={groupIndex} className={styles.groupContainer}>
                      <div
                        className={styles.groupHeader}
                        onClick={() => toggleExpandGroup(groupNumber)}
                      >
                        <span>{t(groupName)}</span>
                        <FontAwesomeIcon
                          icon={isCurrentGroupCompleted ? faCheckSquare : faSquare}
                          className={`${styles.groupIcon} ${
                            isCurrentGroupCompleted && styles.checked
                          }`}
                        />
                      </div>
                      {expandedGroup === groupNumber && (
                        <div className={styles.groupTasks}>
                          {groupTasks.map((task) => (
                            <div key={task.id} className={styles.taskItem}>
                              <div
                                className={styles.taskHeader}
                                onClick={() => toggleExpandTask(task.id)}
                              >
                                <span className={styles.taskTitle}>{t(task.title)}</span>
                                <input
                                  type="checkbox"
                                  checked={task.isCompleted}
                                  onChange={() => toggleTaskCompleted(task.id)}
                                  onClick={(e) => e.stopPropagation()}
                                  className={styles.checkbox}
                                />
                              </div>

                              {expandedTask === task.id && (
                                <div className={styles.taskDescription}>
                                  {task.description}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className={styles.containerDetailsButton}>
                <Link to="/contact-us">
                  <button className={styles.actionButton}>
                    <FontAwesomeIcon icon={faPhone} className={styles.iconPhone} />
                    {t('Call us')}
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            // Placeholder when there are no tasks for the selected individual
            <div className={styles.placeholderContainer}>
              <img
                src={placeholderImage}
                alt="No tasks"
                className={styles.placeholderImage}
              />
              <p className={styles.placeholderText}>
                {t('There are no tasks associated with the selected user.')}
              </p>
            </div>
          )
        ) : allIndividualsCompleted ? (
          // Placeholder when all individuals are at 100%
          <div className={styles.placeholderContainer}>
            <img
              src={placeholderImage}
              alt="No pending tasks"
              className={styles.placeholderImage}
            />
            <p className={styles.placeholderText}>
              {t('All users have completed their tasks. No pending tasks.')}
            </p>
          </div>
        ) : (
          // Placeholder when no individual is selected
          <div className={styles.placeholderContainer}>
            <img
              src={placeholderImage}
              alt="No user selected"
              className={styles.placeholderImage}
            />
            <p className={styles.placeholderText}>
              {t('Please select a user from the left to view tasks.')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TasksCompanies;
